<script setup>

import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import { StateMachine } from '../assets/js/statemachine.js'

import Header from './Header.vue'
import UserName from './UserName.vue'
import Report from './Report.vue'
import Message from './Message.vue'
import Tooltip from './Tooltip.vue'
import ButtonLoad from './ButtonLoad.vue'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

var match_id;


const extra_data = reactive({
    match: null,
    analysis_data: {},
    ratings: null,
    tournament_id: null,
    patron: null,
    advanced: false,
});

onMounted(() => {
    match_id = useRoute().params.match_id;
    sseStore.connect();
    sseStore.addListener("analysis", () => getAnalysis(match_id));
    finalize_match();
    getAnalysis(match_id);
    get_random_patron();
});

onUnmounted(() => {
    sseStore.addListener("analysis", () => {
        messageStore.alertUser("Analysis ready", "The analysis for your match is ready");
    });
});

function replay_game(match_dict){
    const match = new Match();
    match.from_json(match_dict);
    const match_replay = new Match();
    const replay = new StateMachine(match_replay, () => {});
    
    const players = {W: match.commitments[0],
                     B: match.commitments[1]};
    var current_player = 0;
    for(let i=1; i < 10; i++){
        current_player = replay.current_color;
        match_replay.player = players[current_player];
        console.log(current_player);

        const res = replay.next_state(match.get_state(0, i-1));
        match_replay.push_state(res.board);

        console.log(i, match.get_state(0, i).toPositionString(), 
                       res.board.toPositionString(), 
                       res.board.dice.toString());
    }
}

function check_dice(match_dict){
    const match = new Match();
    match.from_json(match_dict);
    
    const player_1 = match.commitments[0];
    const player_2 = match.commitments[1];
    if(player_1 == null || player_2 == null){
        console.log("Could not load player secrets/commitments");
        return false;
    }
    

    const s = new StateMachine()

    match.set_secret(player_1.secret, player_1);
    match.set_secret(player_2.secret, player_2);
    
    const players = {}
    players[player_1.color] = player_1;
    players[player_2.color] = player_2;
    
    
    // First check the commitments
    if(player_1.commitment != match.get_commitment(player_1)){
        console.log("Commitment player 1 not valid!");
        return false;
    }
    if(player_2.commitment != match.get_commitment(player_2)){
        console.log("Commitment player 2 not valid!");
        return false;
    }
    console.log("Commitments are OK");
    
    for(let game of match.games){
        const states = game.states_log.map(x => x[0]);
        const roll_states = states.filter(x => ["R", "PR"].includes(x.game_state));
        
        for(let i=0; i < roll_states.length; i+=2){
            const PR = roll_states[i];
            const R = roll_states[i+1];
            
            const PR_dice = match.get_dice([], PR.move_id, false, null, 
                players[PR.color] // we need the opponent
            );

            const R_dice = match.get_dice(PR_dice, R.move_id, false, null, 
                players[R.color] // we need the opponent
            );
            if(PR_dice.join("") != PR.dice.join("") || 
                R_dice.join("") !=  R.dice.join("")){
                
                console.log("States:", PR.toPositionString(), R.toPositionString())
                console.log("PR:", PR_dice.join(""), PR.dice.join(""), PR.move_id);
                console.log("R: ", R_dice.join(""), R.dice.join(""), R.move_id);
                return false;
            }
        } 
    }
    console.log("Dice are OK");
}

async function get_random_patron(){
    const response = await fetch(app_server + `/user/patron/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();

    if(data.status == "success"){
        extra_data.patron = data.patron;
    }
}
async function getAnalysis(match_id){
    const response = await fetch(app_server + `/match/${ match_id }/analysis/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "config": "any",
            // "recompute": true,
        }),
    });
    const match_data= await response.json();
    
    if(match_data.status == "error"){
        console.log(match_data);
        return;
    }
    extra_data.match = match_data.match;
    extra_data.analysis_data = match_data.analysis;
    extra_data.ratings = match_data.ratings;
    console.log(match_data);
}

async function finalize_match(){
    const response = await fetch(app_server + `/match/${match_id}/finalize/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
            return null;
        }
        return response;    
    });
    if(response === null){
        extra_data.match = null;
        return;
    }
    const finalize_data = await response.json();
    console.log(finalize_data);
    extra_data.match = finalize_data.match;
    extra_data.tournament_id = finalize_data.tournament_id;
    if(finalize_data.match_ != null){
        check_dice(finalize_data.match_);
    }else{
        console.log("No match");
    }
    // replay_game(finalize_data.match_);
    
    localStorage.removeItem("current_match");
    userStore.loadUser(true); // We force reload the user so we get the latest data.
}

function get_rating_diff(){
    if(extra_data.ratings == null || extra_data.match == null){
        return {W: 0, B: 0}
    }
    if( extra_data.ratings.W == null || extra_data.ratings.W == null){
        return {
            W: 0,
            B: 0,
        }
    }
    const old_w = parseFloat(extra_data.match.white.rating);
    const old_b = parseFloat(extra_data.match.black.rating);
    
    const new_w = parseFloat(extra_data.ratings.W);
    const new_b = parseFloat(extra_data.ratings.B);

    
    return {
        W: (new_w - old_w).toFixed(1),
        B: (new_b - old_b).toFixed(1),
    }
}

function get_elo_diff(match){
    if(match.white.elo == null || match.black.elo == null){
        return {W: 0, B: 0}
    }
    const old_w = parseFloat(match.white.elo);
    const old_b = parseFloat(match.black.elo);
    
    const new_w = parseFloat(match.white.new_elo);
    const new_b = parseFloat(match.black.new_elo);
    
    return {
        W: (new_w - old_w).toFixed(2),
        B: (new_b - old_b).toFixed(2),
    }
}
function get_coins_diff(match){
    if(match.white.coins == null || match.black.coins == null){
        return {W: 0, B: 0}
    }
    const old_w = parseFloat(match.white.coins);
    const old_b = parseFloat(match.black.coins);
    
    const new_w = parseFloat(match.white.new_coins);
    const new_b = parseFloat(match.black.new_coins);
    
    return {
        W: (new_w - old_w).toFixed(2),
        B: (new_b - old_b).toFixed(2),
    }
}

function is_rating_game(){
    return extra_data.match.points >= 5;
}

</script>
<template>
    <Header />
    <Message />
    <div
        class="w-fit mx-auto my-20"
    >
    <div class="" v-if="extra_data.match == null">
        Checking the match, please wait...
    </div>
    <div class="" v-if="extra_data.analysis_data.status != 'analysed'">
        Analyzing the match, please wait...
    </div>

    <table 
        class="table-fixed text-lg mb-10 px-1 w-full md:w-auto border-separate border-spacing-2"
        v-if="extra_data.match != null"
    >
        <tbody>
            <tr class="border-b-2">
                <td class="md:w-60 font-semibold flex justify-start items-center h-16">
                    <UserName :user="extra_data.match.white" class="text-2xl"/>
                </td>
                <th class="md:w-60">
                    <div class="flex justify-center items-center gap-x-4">
                        <div class="font-semibold text-3xl text-center tabular-nums">
                            {{ extra_data.match.score.W }}
                        </div>
                        <span>-</span>
                        <div class="font-semibold text-3xl text-center tabular-nums">
                            {{ extra_data.match.score.B }}
                        </div>
                    </div>
                </th>
                <td class="md:w-60 font-semibold text-right flex justify-end">
                    <UserName :user="extra_data.match.black"  class="text-2xl"/>
                </td>
            </tr>
            <tr v-if="extra_data.match.match_type == 'pvp'">
                <td>
                    {{ extra_data.match.white.rating.toFixed(2) }}
                    (<span v-if="get_rating_diff().W > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_rating_diff().W).toFixed(2) }})
                </td>
                <th>Rating</th>
                <td class="text-right">
                    (<span v-if="get_rating_diff().B > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_rating_diff().B).toFixed(2) }})
                    {{ extra_data.match.black.rating.toFixed(2) }}
                </td>
            </tr>
            <!--
            <tr v-if="extra_data.match.white.new_elo">
                <td>{{ extra_data.match.white.new_elo.toFixed(2) }} ( 
                    <span v-if="get_elo_diff(extra_data.match).W > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_elo_diff(extra_data.match).W).toFixed(2) }})
                </td>
                <th>Elo</th>
                <td class="text-right">
                    (
                    <span v-if="get_elo_diff(extra_data.match).B > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_elo_diff(extra_data.match).B).toFixed(2) }})
                    {{ extra_data.match.black.new_elo.toFixed(2) }}
                </td>
            </tr>
            -->
            <tr v-if="extra_data.match.white.new_coins">
                <td>{{ extra_data.match.white.new_coins.toFixed(0) }} ( 
                    <span v-if="get_coins_diff(extra_data.match).W > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_coins_diff(extra_data.match).W).toFixed(0) }})
                </td>
                <th>Sparkles</th>
                <td class="text-right">
                    (
                    <span v-if="get_coins_diff(extra_data.match).B > 0">+</span> 
                    <span v-else>-</span>{{ Math.abs(get_coins_diff(extra_data.match).B).toFixed(0) }})
                    {{ extra_data.match.black.new_coins.toFixed(0) }}
                </td>
            </tr>
            <tr v-if="extra_data.analysis_data.status == 'analysed'">
                <td>{{ extra_data.analysis_data.analysis.match_stats.W.ER.toFixed(2)}}</td>
                <th>ER</th>
                <td class="text-right">
                    {{ extra_data.analysis_data.analysis.match_stats.B.ER.toFixed(2)}}
                </td>
            </tr>
            <tr v-if="extra_data.analysis_data.status == 'analysed' && extra_data.advanced">
                <td>{{ extra_data.analysis_data.analysis.match_stats.W.XR.toFixed(2)}}</td>
                <th>Gnu PR</th>
                <td class="text-right">
                    {{ extra_data.analysis_data.analysis.match_stats.B.XR.toFixed(2)}}
                </td>
            </tr>
            <tr v-if="extra_data.analysis_data.status == 'analysed'">
                <td>
                    {{ extra_data.analysis_data.analysis.match_stats.W.EQ.toFixed(3)}}
                </td>
                <th>Lost Equity</th>
                <td class="text-right">
                    {{ extra_data.analysis_data.analysis.match_stats.B.EQ.toFixed(3)}}
                </td>
            </tr>
            <!--
            <tr v-if="extra_data.match.white.expected_level && is_rating_game()">
                <td>
                    {{ extra_data.match.white.expected_level[2] }} 
                    ({{ extra_data.match.white.expected_level[0] }})
                </td>
                <th>Expected Level/PR</th>
                <td class="text-right">
                    {{ extra_data.match.black.expected_level[2] }}
                    ({{ extra_data.match.black.expected_level[0] }})
                </td>
            </tr>
            -->
            <tr v-if="extra_data.analysis_data.status == 'analysed'"
            >
                <td>
                    {{ extra_data.analysis_data.analysis.match_stats.W.level[2] }}
                </td>
                <th>Level of Play</th>
                <td class="text-right">
                    {{ extra_data.analysis_data.analysis.match_stats.B.level[2] }}
                </td>
            </tr>
            <tr>
                <td />
                <td @click="extra_data.advanced ^= true" class="text-center font-thin h-12 cursor-pointer">
                    <span v-if="!extra_data.advanced">+ Show more</span> 
                    <span v-else>- Show less</span> 
                </td>
                <td />
            </tr>
            <!--
            <tr class="h-20">
                <td class="font-semibold text-3xl text-center tabular-nums">
                    {{ extra_data.match.score.W }}
                </td>
                <th></th>
                <td class="font-semibold text-3xl text-center tabular-nums">
                    {{ extra_data.match.score.B }}
                </td>
            </tr>
            -->
        </tbody>        
    </table>
    <div class="flex space-x-2 justify-center relative px-24">
        <router-link :to="{name: 'analysis', param: {match_id: match_id}}" class="grow">
            <button class="btn btn-blue h-12 w-full" 
                :disabled="extra_data.analysis_data.status != 'analysed'"
            >
                Analysis
            </button>
        </router-link>
        <router-link v-if="extra_data.match && extra_data.match.match_type == 'bot'" class="grow"
                    :to="{name: 'bot', param: {}}">
            <button class="btn btn-blue w-full h-12">
                Bot List 
            </button>
        </router-link>
        <router-link v-else :to="{name: 'home', param: {}}" class="grow">
            <button class="btn btn-blue w-full h-12">
                Home 
            </button>
        </router-link>
        <router-link  v-if="extra_data.tournament_id != null" class="grow" 
                      :to="{name: 'tournament-info', params: {tournament_id: extra_data.tournament_id}}" 
                    >
            <button class="btn btn-blue w-full h-12">
                Tournament Info 
            </button>
        </router-link>
        <router-link  v-if="extra_data.match && extra_data.match.match_type == 'bot'"  class="grow"
                      :to="{name: 'bot-match', params: {bot_id: extra_data.match.black.user_id}}" 
                    >
            <button class="btn btn-blue w-full h-12">
                Play Again
            </button>
        </router-link>
        <Report :object_type="'match'" :object_id="match_id"/>
    </div> 

    <div class="flex flex-col items-center my-24 gap-y-2">
        <div>
            <Tooltip text="OpenGammon runs on donations by our patrons.">
                <span class="font-thin">
                    This match has been donated by:
                </span>
            </Tooltip>
        </div>
        <UserName v-if="extra_data.patron != null"
            class="text-lg" :user="extra_data.patron" 
        />
        <span class="font-thin">
            Visit the 
            <router-link :to="{name: 'donate'}" 
                class="text-field-color hover:underline">
                donate page
            </router-link> 
            for more information. 
        </span>
    </div>

    </div> 
</template>

<style scoped>
</style>
