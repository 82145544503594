/*
 * A backgammon move consists of the following actions:
 *
 * 1) Cube action
 *  a) No cube action
 *  b) offer -> take
 *  c) offer -> pass / resign
 * 2) Roll
 * 3) Move checkers
 *
 */

export class BoardState {
    /* An encoder/decoder for backgammon board states, which is both human
     * readable and relatively compact 
     * 
     * <white-position>:<black-position>:<cube-value>:<dice>:<color>:<game-state>:<points-white>:<points-black>
     *
     * The positions are encoded as follows:
     * [pip(s) for s in stones], where pip is the base 26 encoded pip number (absolute)
     *
     * The cube value is encoded as follows:
     * <ownership><value><action> where
     *
     * Ownership: /B|W|N|D|?/ -> B(lack) W(hite) N(one) D(ead) ?(initial)
     * Value: /\d+/ -> encoded in log base 2
     * Action: /O|P|T|N/ -> O(ffer) P(ass) T(ake) N(one)
     *
     * The dice are encoded as two numbers: /[1-6][1-6]/
     * The color encodes which player has to take action as
     * /W|B/
     *
     * Note that the board is stored with absolute coordinates, with 0 and 25 being
     * the bar points for respectively white and black.
     *
     * TODO For match play we want to actually add the relative score (so x-away y-away)
     * to the board state to be able to evaluate doubling decisions.
     */
    white_positions;
    black_positions;
    cube;
    dice;
    color;
    score;
    game_state;
    match_length;
    is_crawford;
    move_id;
    
    default_position = "11jjjjjhhhccccc:ooddddd88866666:N0N::W:IW:0:0:0";

    cube_regex = /([BWND\?])(\d+)([OTPN])/m;

    opponent = {'W': 'B', 'B': 'W'};
    bar_points = {'W': 0, 'B':25};
    
    constructor(positionString){
        if(!positionString){
            positionString = this.default_position;
        }

        this.points = [];
        this.positionString = positionString;
        this.parsePosition();
        this.getPoints();
    }

    update(){
        this.positionString = this.toPositionString();
        this.parsePosition();
        this.getPoints();
    }

    parsePosition(){
        const parts = this.positionString.split(":");
        this.white_positions = parts[0];
        this.black_positions = parts[1];
        this.cube = this.parseCube(parts[2]);
        this.dice = this.getDice(parts[3]);
        this.color = parts[4];
        this.game_state = parts.length < 6 ? "IW" : parts[5];
        this.score = parts.length < 8 ? [0,0] : [
            parseInt(parts[6]), parseInt(parts[7])];
        this.match_length = parts.length < 9 ? 0 : parseInt(parts[8]);
        this.is_crawford = parts.length >= 9 && parts[8].slice(-1) == "C";
        this.move_id = parts.length < 10 ? 0 : parseInt(parts[9]);
    }

    getStones(){
        /* returns an object with the number of stones placed on each pip 
           
           if the number of stones is negative the color is black
           otherwise the color is positive.
           
           We assume the position given is a legal position, otherwise the 
           output is undefined
        */
        
        const stones = Object()
        for(const stone of this.white_positions){
            const pip = parseInt(stone, 26);
            stones[pip] = (stones[pip] || 0) + 1;
        }
        for(const stone of this.black_positions){
            const pip = parseInt(stone, 26);
            stones[pip] = (stones[pip] || 0) - 1;
        }
        return stones; 
    }

    getPoints(){
        // Create the points
        const stones = this.getStones();
        this.points.length = 0; // empty the array without changing the ref
        for(let i=0; i <= 25; i++){
            if( i in stones){
                this.points.push({
                    nrof_stones: Math.abs(stones[i]),
                    id: i,
                    color : (stones[i] > 0) ? "W" : "B",
                    highlight: false,
                })
            }else{
                this.points.push({
                    nrof_stones: 0,
                    id: i,
                    color:"",
                    highlight: false,
                })
            }
        }
        // move the bar points
        this.points.splice(7, 0, this.points.splice(25, 1)[0])
        this.points.splice(19, 0, this.points.splice(0, 1)[0])
        // set the bar point colors
        this.points[this.get_point_index(this.bar_points["W"])].color = "W";
        this.points[this.get_point_index(this.bar_points["B"])].color = "B";
    }

    setDice(dice_str){
        this.dice = this.getDice(dice_str)
    }

    getDice(dice_str){
        /* 
         */
        const dice = [];
        for(const d of dice_str){
            dice.push(parseInt(d));
        }
        return dice;
    }

    getGameState(state_str){
        
    }

    parseCube(cube_str){
        const cube_match = cube_str.match(this.cube_regex);

        if(!cube_match){
            // We return a dead cube at 1
            return {owner:"N", value:0, action:"N"};
        }
        const cube = {
            owner: cube_match[1],
            value: parseInt(cube_match[2]),
            action: cube_match[3],
        }
        return cube;
    }   

    stringCube(){
        return Object.values(this.cube).join("");
    }

    can_bearoff(from_point_id, color, pips_to_move){
        var matches, points, relative_position;    
        if(color == "W"){
            matches = this.white_positions.match(/[0-9a-i]/);
            points = [...this.white_positions].filter(x => parseInt(x, 26) < from_point_id);
            relative_position = 25 - from_point_id;
        }else{
            matches = this.black_positions.match(/[7-9a-p]/);
            points = [...this.black_positions].filter(x => parseInt(x, 26) > from_point_id);
            relative_position = from_point_id;
        }
        let all_points_in_home_board = (matches == null);
        let exact_bearoff = (relative_position == pips_to_move);
        let last_stone = (points.length == 0);  

        return all_points_in_home_board && (exact_bearoff || last_stone);
    }

    get_point_index(point_id){
        return this.points.findIndex( (x) => x.id == point_id);
    }

    possibleMoves(from_point_id, color, dice=[]){
        /* checks all possible moves for this color from this pip */
        const from_point = this.points[this.get_point_index(from_point_id)];
        const bar_point = this.points[this.get_point_index(this.bar_points[color])];

        if(from_point.color !== color || from_point.nrof_stones == 0){ // does the color own the pip?
            return [];
        }
        if(bar_point.nrof_stones > 0 && from_point.id != bar_point.id){ 
            // Check if the bar point is occupied and if occupied we need to
            // play from the bar point
            return [];
        }
        const possible_moves = [];
        const pips_to_move = dice ? dice : [1,2,3,4,5,6]; 
        for(const i of pips_to_move){
            const to_point_id = color == 'W' ? from_point_id + i : from_point_id - i;
            if((to_point_id < 1 || to_point_id > 24)){ // we move off the board
                if(this.can_bearoff(from_point_id, color, i)){
                    possible_moves.push(-1);
                }
                continue;
            }
            
            const to_point = this.points[this.get_point_index(to_point_id)];
            
            if(to_point.color === color || !to_point.color ){
                // The point is owned by <color> or not owned by anyone
                possible_moves.push(to_point_id);
            }
            else if(to_point.color !== color && // added this clause for clarity
                    to_point.nrof_stones <= 1){
                possible_moves.push(to_point_id);
            }
        } 
        return possible_moves;
    }

    getValidStates(active_dice=null, pips_played=0, played_move=[]){
        /*
         * This returns a object of valid states
         * 
         * Base Case: no dice to apply -> return the valid states
         *
         * Step:
         *  - Apply each dice to all valid states
         *  - Compute the valid states with the remaining dice
         */
        if(active_dice == null){
            active_dice = this.dice.slice();
            if(active_dice.length == 2 && active_dice[0] == active_dice[1]){
                active_dice.push(active_dice[0]);
                active_dice.push(active_dice[0]);
            }
        }
        const player_color = this.opponent[this.color];
        if(this.cube.action == "O"){
            const accept = this.copy();
            const pass = this.copy();
            accept.cube.action = "T";
            accept.cube.value += 1;
            accept.cube.owner = this.color;
            
            pass.cube.action = "P";
            accept.cube.owner = this.color;

            return {
                [this.toPositionString()]: [accept.toPositionString(true), 0, ["Accept"]] 
            }
        }

        if(active_dice.length == 0){ // Base case
            const position_str = this.toPositionString(true);
            return {[position_str]: [this, pips_played, played_move]};
        }

        const new_valid_states = {};
            
        // console.log("DICE", active_dice);
        for(const dice_index in active_dice){
            if(active_dice.length > 1 && active_dice[0] == active_dice[1]){
                // If we throw double we don't need to walk down the tree for every dice (the active states will be the same)
                if(dice_index > 0)
                    break;
            }
            const dice = active_dice[dice_index];
            const new_dice = active_dice.slice();
            new_dice.splice(dice_index, 1);

            for(let point_id=0; point_id <= 25; point_id++){
                let new_state = this.moveStone(point_id, dice, player_color);
                if(new_state){
                    // console.log(active_dice, point_id, dice, new_state.toPositionString());
                    const new_position = new_state.toPositionString(true);
                    if(new_position in new_valid_states){
                        new_valid_states[new_position].push(
                            [new_state, pips_played + dice, [...played_move, [point_id, dice]]]
                        );
                    }else{
                        new_valid_states[new_position] = [
                            [new_state, pips_played + dice, [...played_move, [point_id, dice]]]
                        ];
                    }
                    
                    // We compute the new states and combine them with the current valid states
                    const v_state = new_state.getValidStates(
                              new_dice, pips_played + dice, [...played_move, [point_id, dice]]);
                    // console.log("BEFORE", new_valid_states);
                    // console.log(v_state);
                    for(const s in v_state){
                        if( s in new_valid_states){
                            new_valid_states[s].push(...v_state[s]);
                        }else{
                            new_valid_states[s] = [...v_state[s]];
                        }
                    }
                    // console.log("AFTER", new_valid_states);
                }
            }
        }
        // console.log(new_valid_states);
        // console.log(played_move);
        if(played_move.length == 0){
            // const max_pips_played = Math.max(...Object.values(new_valid_states).map( x => x[1]));
            var max_pips_played = 0;
            for(const key in new_valid_states){
                for(const play of new_valid_states[key]){
                    if(play[1] > max_pips_played){
                        max_pips_played = play[1];
                    }
                }
            }
            const max_pip_states = {};
            // console.log("MAX PIPS", max_pips_played);
            // console.log(new_valid_states);
            
            for(const key in new_valid_states){
                const mp =  new_valid_states[key].filter((x) => x[1] == max_pips_played );
                if(mp.length > 0){
                    max_pip_states[key] = mp;
                }
            }

            // console.log("VALID STATES", active_dice, max_pip_states);
            return max_pip_states;
        }
        return new_valid_states;
    }

    getValidMoves(active_dice){
        const valid_states = this.getValidStates(active_dice);

        const valid_moves = {};
        for(const state in valid_states){
            const moves = valid_states[state].map(x => x[2]).flat();
            for(const move of moves){
                if(move == null){
                    continue;
                }
                const from_point = move[0];
                const nrof_pips = move[1];
                if(from_point in valid_moves){
                    valid_moves[from_point].push(nrof_pips);
                }else{
                    valid_moves[from_point] = [nrof_pips];
                }
                //console.log(from_point, nrof_pips, valid_moves);
            }
        }
        return valid_moves;
    }
    
    moveStoneAbs(from_point_id, to_point_id, color){
        // We can move this pip
        let new_state = this.copy();

        if(color == "W" && from_point_id == 25){
            from_point_id = 0;
        }
        const from_point = new_state.points[this.get_point_index(from_point_id)];

        const opponent_bar_point_id = new_state.get_point_index(new_state.bar_points[
                new_state.opponent[color]]);
        const opponent_bar_point = new_state.points[opponent_bar_point_id];

        if(to_point_id < 0){ // move the stone of the board
            from_point.nrof_stones--;
        }else{
            const to_point = new_state.points[this.get_point_index(to_point_id)];
            // We always have to remove one stone from the <from_point>
            from_point.nrof_stones--;
            if(to_point.color == color){
                to_point.nrof_stones++;
            }else if(!to_point.color){
                to_point.nrof_stones = 1;
                to_point.color = color;
            }else{ // we hit a stone
                opponent_bar_point.nrof_stones++; // move to bar
                to_point.color = color;
                to_point.nrof_stones = 1; // Added for clarity
                // move_str += "*";
            } 
            // Error: Negative number of stones
            if(from_point.nrof_stones < 0){
                console.error("Negative number of stones", from_point, to_point, from_point_id);
            }
            // Clear the color of the point if no stones are present
            const bar_point_ids = Object.values(this.bar_points);
            if(from_point.nrof_stones == 0 && 
                !bar_point_ids.includes(from_point.id) // not the bar point
            ){
                from_point.color = "";
            }
        }
        
        new_state.positionString = new_state.toPositionString();
        new_state.parsePosition();
        new_state.getPoints();

        return new_state;
    }   

    moveStone(from_point_id, nrof_pips, color){
        /* Do some basic checks TODO implement all rules */
        const moves = this.possibleMoves(from_point_id, color, [nrof_pips]);

        if(moves.length == 0){
            return false;
        }
        const to_point_id = moves[0]; // moves contains exactly one element
    
        return this.moveStoneAbs(from_point_id, to_point_id, color);
    }

    clear_color(color){
        for(const point of this.points){
            if(point.color == color){
                point.nrof_stones = 0;
            }
        }
    }

    toPositionString(only_position=false){
        /* converts the current board to a position string */ 
        let white_stones = "", black_stones = "";
        for(const point of this.points){
            if(point.color == "W" && point.nrof_stones > 0){
                white_stones += point.id.toString(26).repeat(point.nrof_stones)
            } else if(point.color == "B" && point.nrof_stones > 0){
                black_stones += point.id.toString(26).repeat(point.nrof_stones)
            }
        }
        const dice = this.dice.join("");
        const cube = this.stringCube();
        const score = this.score.join(":");
        const match_length = this.match_length + ((this.is_crawford) ? "C" : "");

        let position_str = `${white_stones}:${black_stones}:${cube}`
        if(! only_position){
            position_str += `:${dice}:${this.color}:${this.game_state}:${score}:${match_length}:${this.move_id}`;
        }
        return position_str;
    }

    copy(){
        return new BoardState(this.toPositionString());
    }

    getMove(next, absolute=false){ // input the next board state to get the moves
        const move = {
            text: "",
            data: [],
        };

        const dice = this.dice.slice();
        if(next.cube.action == "O"){
            move.text = `Doubles => ${2**(next.cube.value+1)}`;
            return move;
        }else if(next.cube.action == "T"){
            move.text = `Takes`;
            return move;
        }else if(next.cube.action == "P"){
            move.text = `Drops`;
            return move;
        }

        if(this.cube.action == "T"){
            this.cube.action = "N";
        }

        if(dice.length > 1 && dice[0] == dice[1]){
            dice.push(dice[0]);
            dice.push(dice[0]);
        }
        const valid_states = this.getValidStates(dice);
        const this_position = this.toPositionString();
        const next_position = next.toPositionString(true);

        const chosen_state = valid_states[next_position];
        
        if(chosen_state == null || chosen_state.length < 1){
            return move;
        }

        var [state, pips_moved, moves] = chosen_state[0];

        if(moves.length == 0){
            move.text = " ";
        }else{
            if(!absolute){
                if(next.color == "W"){
                    moves = moves.map(x => [25-x[0], x[1]]); 
                }
            }
            if(next.color == "B" || !absolute){
                move.data = moves.map(x => [x[0], Math.max(0, x[0] - x[1])]); 
            }else{
                move.data = moves.map(x => [x[0], Math.max(0, x[0] + x[1])]); 
            }
            // var text = moves.map(x => [x[0], x[1]].join("/") + (x[2] ? "*": ""));
            const text = move.data.map(x => [x[0], x[1]].join("/"));
            move.text = text.join(" ");
        }

        return move;
    }

    get_resign_value(color){
        const copy = this.copy();
        
        var position = null;
        var game_value = 0;
        if(color == "W"){
            position = this.white_positions;
        }else{
            position = this.black_positions;
        }

        if(position.length < 15){
            game_value = 1;
        }
        else if(color == "B" && /[ponmlkj]/.test(position)){
            game_value = 3;
        }else if(color == "W" && /[0123456]/.test(position)){
            game_value = 3;
        }else {
            game_value = 2;
        }

        return game_value;
    }

    is_won(){
        /* 
         * Returns 0 if this state has not been won
         *
         * Positive if white has won
         * Negative if black has won
         *
         * TODO implement backgammon
         */
        var game_value = 0; 
        if(this.cube.action == "P"){
            if(this.color == "W"){
                game_value = 1;
            }else{
                game_value = -1;
            }
        }else if(this.white_positions.length == 0){
            if(this.black_positions.length < 15){
                game_value = 1;
            }
            else if(/[ponmlkj]/.test(this.black_positions)){
                game_value = 3;
            }else {
                game_value = 2;
            }
        }else if(this.black_positions.length == 0){
            if(this.white_positions.length < 15){
                game_value = -1;
            }
            else if(/[0123456]/.test(this.white_positions)){ // backgammon
                game_value = -3;
            }else{ //gammon
                game_value = -2;
            }
        }else{
            game_value = 0;
        }
        
        return game_value * 2**this.cube.value;
    }

    get_difference(next){
        /*
         * Returns the points and number of stones that are different
         */
        const differences = {};
        for(const point_index in this.points){
            const point_1 = this.points[point_index];
            const point_2 = next.points[point_index];

            if(point_1.color == point_2.color && point_1.nrof_stones != point_2.nrof_stones){
                differences[point_index] = point_2.nrof_stones - point_1.nrof_stones;
            }else if(point_1.color != point_2.color && point_2.nrof_stones > 0){
                differences[point_index] = point_2.nrof_stones;
            }
        }
        return differences;
    }

    get_pipcount(){
        const pipcount = {"W": 0, "B": 0};
        for(const point of this.points){
            if(point.color == "W"){
                pipcount[point.color] += point.nrof_stones * (25 - point.id);
            }else if(point.color == "B"){
                pipcount[point.color] += point.nrof_stones * (point.id);
            }
        }
        return pipcount;
    }
    
    get_nrof_born_off(){
        return {
            "W": 15 - this.white_positions.length, 
            "B": 15 - this.black_positions.length
        };
    }

    to_xgid(){
        /*
         * B -> top player
         * W -> bottom player (our point of view)
         */
        const positions = "abcdefghijklmnop";
        var xg_id = "XGID="

        for(let i=0; i < 26; i++){
            const point = this.points[this.get_point_index(i)];
            if(point.color == "B" && point.nrof_stones > 0){
                xg_id += positions[point.nrof_stones - 1].toUpperCase();
            }else if(point.color == "W" && point.nrof_stones > 0){
                xg_id += positions[point.nrof_stones - 1];
            }else{
                xg_id += "-";
            }
        }
        var cube_position = 0;
        var cube_value = this.cube.value;
        if(this.cube.owner == "W"){ // bottom player has the cube
            cube_position = -1;
        }else if(this.cube.owner == "B"){ // top player has the cube
            cube_position = 1;
        }
        var turn = 0;
        if(this.color == "B"){ // B -> it's W's turn
            turn = -1;
        }else if(this.color == "W"){
            turn = 1;
        }
        var dice;
        if(this.game_state == "C"){
            dice = "00";
        }else if(this.game_state == "D"){
            dice = "00";
            turn *= -1; // The turn swaps in a double situation
        }else{
            dice = this.dice.join("");
        }
        
        const crawford = this.crawford? "1" : "0"
        xg_id += `:${cube_value}:${cube_position}:${turn}:${dice}:${this.score[1]}:${this.score[0]}:${crawford}:${this.match_length}:8`
        return xg_id;
    }
}


