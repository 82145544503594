<script setup>
import { useRoute, useRouter } from 'vue-router'

import Board from './Board.vue'
import CreatePuzzle from './CreatePuzzle.vue'
import Message from './Message.vue'
import Header from './Header.vue'

import { Match } from '../assets/js/match.js'
import {BoardState} from '../assets/js/board.js'
import {ref, computed, reactive, onMounted} from 'vue'

import {PuzzlePieceIcon, CpuChipIcon} from '@heroicons/vue/24/solid'

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend
)

const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const analysis_configs = ["1ply", "2ply", "3ply"];

const chart_options = {
    responsive: true,
    maintainAspectRatio: false,
    scales:{
        x:{
            ticks:{
                display: false,
            },
            grid:{
                display: false,
            }
        },
        y: {
            ticks:{
                stepsize: 0.5,
            },
            title: {
                "text": "EQ",
            },  
            suggestedMin: -1,
            suggestedMax: 1,
        },
    },
    elements: {
        point: {
            pointRadius: 1,
        },
    }, 
    plugins:{
        legend: {display: false},
    },
};

function get_chart_data(){
    const style = getComputedStyle(document.body);
    const field_color = style.getPropertyValue('--field-color');
    const case_color = style.getPropertyValue('--case-color');
    const moves = get_moves(extra_data.selected_game);
    console.log(moves);
    
    const chart = [0];
    const error_chart = [];
    const blunder_chart = [];

    var lastEQ = 0
    for(const move_index in moves){
        const move = moves[move_index];
        var EQ = 0;
        if(["double", "take", "pass"].includes(move.move_sgf)){
            const actions = Object.fromEntries(move.cube_recommendation.actions);
            EQ = actions[move.cube_recommendation.best];
            console.log(EQ, move);
        }
        else if(move.move_played != null && move.move_analysis[move.move_played]){
            EQ = move.move_analysis[move.move_played].eq;
        }else{
            EQ = -lastEQ;
        }
        var error = Math.max(...get_error(move));
        if(move.state.color == "W"){
            EQ *= -1;
        }

        lastEQ = EQ;
        chart.push(EQ);

        if(get_move_class(move).includes("error")){
            if(move.state.color == "W"){
                error_chart.push({
                    x: parseInt(move_index) + 1,
                    y: 1,
                });
            }else{
                error_chart.push({
                    x: parseInt(move_index) + 1,
                    y: -1,
                });
            }
        }
        if(get_move_class(move).includes("blunder")){
            if(move.state.color == "W"){
                blunder_chart.push({
                    x: parseInt(move_index) + 1,
                    y: 1,
                });
            }else{
                blunder_chart.push({
                    x: parseInt(move_index) + 1,
                    y: -1,
                });
            }
        }
    }
    
    var labels = [...Array(chart.length).keys()];
    labels = labels.map((x) =>  x );

    const chart_data = {
        labels: labels,
        datasets: [
            {
                label: "EQ",
                data: chart,
                borderColor: field_color,
            },
            {
                type: "bar",
                label: "EQ",
                data: blunder_chart,
                maxBarThickness: 4,
                borderColor: case_color,
                borderWidth: 0.5,
                backgroundColor: "#FF0000",
            },
            {
                type: "bar",
                label: "EQ",
                data: error_chart,
                maxBarThickness: 4,
                borderColor: case_color,
                borderWidth: 0.5,
                backgroundColor: "#FFFF00",
            },
        ],
    }

    return chart_data;
}

async function copy_to_clipboard(text){
    if(text == null){
        text = window.location.href;
    }

    console.log(text);
    if(window.isSecureContext){
        await navigator.clipboard.writeText(text);
        messageStore.alertUser("Share", "Copied XGID to clipboard.");
    }
}

function get_xgid(){
    const bs = new BoardState(extra_data.current_board)
    return bs.to_xgid();
}

const state = reactive({
    current: undefined,
    alt: 0,
    current_board: undefined,
});

const extra_data = reactive({
    info: null,
    selected_game: null,
    analysis: null,
    requested_3ply_analysis: false,
    analysis_data: {},
    user_color: "W",
    arrows: [],
    show: "moves",
    analysis_config: "2ply",
    reloading_analysis: false,
    loading_export: false,
    creating_puzzles: false,
    message:{"type":"success", "text":""}
});

const puzzle_data = reactive({
    state: "",
});

const cube_action_names = {
    "eq_nd": "No double",
    "eq_dt": "Double/Take",
    "eq_dp": "Double/Pass",
};

var match_id;
const match = reactive(new Match());

// Add a key event to navigate through the game
window.addEventListener("keydown", function(e){
    if(e.code == "ArrowDown"){
        state.current += 2;
        selectMove(state.current);
    }
    if(e.code == "ArrowUp"){
        state.current -= 2;
        selectMove(state.current);
    }
    if(e.code == "ArrowRight"){
        state.current += 1;
        selectMove(state.current);
    }
    if(e.code == "ArrowLeft"){
        state.current = Math.max(0, state.current - 1);
        selectMove(state.current);
    }
});

onMounted(() => {
    match_id = useRoute().params.match_id;
    getMatch();
    sseStore.addListener("analysis", () => getAnalysis());
    getAnalysis();
    sseStore.connect();
});

async function getMatch(){
    const response = await fetch(app_server + `/match/${ match_id }/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const match_data = await response.json();
    match.from_json(match_data.match);
    extra_data.info = match_data.info;
    extra_data.selected_game = 0;
    state.current = 0;

    console.debug("MATCH", match);
    for(let game of match.games){
        console.debug("STATES", game.states_log.map(x => x[0].toPositionString()));
    }

    get_dice_stream();
}

async function getAnalysis(reload_analysis=false, analysis_config="any"){
    if(reload_analysis){
        console.log("Reloading the analysis");
        extra_data.reloading_analysis = true;
    }
    const response = await fetch(app_server + `/match/${ match_id }/analysis/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "config": analysis_config,
            "recompute": reload_analysis,
        }),
    });
    const match_data = await response.json();
    
    if(match_data.status == "error"){
        console.error(match_data);   
        return;
    }
    if(match_data.analysis.status != 'analysed'){
        console.log("Analysis not yet finished:", match_data.analysis);
        return;
    }
    if(!match_data.analysis.analysis){
        console.log("Could not load analysis!");
        return;
    }
    console.log("ANALYSIS", match_data);
    extra_data.analysis_data = match_data.analysis;
    extra_data.analysis = match_data.analysis.analysis;
    extra_data.match = match_data.match;
    extra_data.user_color = match_data.user_color;
    console.log(get_moves(0));    
    selectMove(0);
    extra_data.reloading_analysis = false;
    

    messageStore.alertUser("Analysis", "The analysis has loaded successfully");
}

async function getPositionAnalysis(position_id, move_played=null, analysis_config="2ply"){
    const response = await fetch(app_server + `/position/${ position_id }/analysis/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "config": analysis_config,
            "move_played": move_played,
        }),
    });
    const analysis_data = await response.json();
    
    if(analysis_data.status == "error"){
        return;
    }
    return analysis_data;
}

async function getMatchExport(format="mat"){
    extra_data.loading_export = true;
    const response = await fetch(app_server + `/match/${ match.match_id }/export/?format=${format}`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const match_data = await response.json();
    const filename = match.match_id + "." + format;
    const text = match_data.match;
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    extra_data.loading_export = false;
}

function get_dice_stream(){
}

function getCurrentPosition(){
    if(state.current === undefined || match.games.length == 0 || extra_data.selected_game == undefined){
        return undefined;
    }
    var pos;
    const selected_game = match.games[extra_data.selected_game];

    if(selected_game.states.length < state.current){
        state.current = 0;
    }
    
    const move_states = selected_game.get_move_states(state.current);
    
    /* TODO rewrite this, we should actually want get_move_states to just return
        two states, before the action and after the action
    */
    if(move_states.length == 0){ 
        return new BoardState().toPositionString();
    }else if(move_states.length == 1){
        pos = move_states[0];
    }else{
        pos = move_states.find((x) => ["R", "IB"].includes(x.game_state));
        // pos = move_states[1];
        if(pos){
            pos.game_state = "R";
        }else{
            pos = move_states[0];
        }
        
    }
    return pos.toPositionString();
}

function get_move(game_id, move_id){
    /*
        Returns an object containing the information for the move.
    */
    if(extra_data.analysis == null || Object.keys(extra_data.analysis).length == 0){
        return {};
    }
    if(game_id >= extra_data.analysis.games.length){
        return [];
    }
    const move_analysis_fields = ["ply", "move", "eq", "win", "win_g", "win_bg", "lose_g", "lose_bg"];
    const cube_analysis_fields = ["ply", "win", "win_g", "win_bg", "lose_g", "lose_bg", 
                                  "eq", "eq_nd", "eq_dt", "eq_dp"];

    const game_analysis = extra_data.analysis.games[extra_data.selected_game];
    move_id = Math.max(0, Math.min(move_id, game_analysis.moves.length-1));

    const move_array = game_analysis.moves[move_id];
    const move = {};
    
    if(move_array[0] == null){
        return move;
    }
    move.state = new BoardState(move_array[0]);
    move.state.color = move.state.opponent[move.state.color];
    move.move_sgf = move_array[1];
    move.move = parse_sgf_move(move_array[1], move.state);
    move.move_played = move_array[2].findIndex(x => move_array[1].includes(x[1]));
    move.move_analysis = move_array[2].map( (x) => Object.fromEntries( 
            x.map( 
                (val, i) => [move_analysis_fields[i], val]
            )
        ));
    move.cube_analysis = Object.fromEntries( move_array[3].map( 
                (val, i) => [cube_analysis_fields[i], val]));
    move.cube_recommendation = get_cube_recommendation(move.cube_analysis, move.move_sgf);
    
    for(let alt of move.move_analysis){
        alt.move = parse_sgf_move(alt.move, move.state);
    }

    return move;
}

function get_moves(game_id){
    if(extra_data.analysis == null || Object.keys(extra_data.analysis).length == 0){
        return [];
    }
    if(game_id >= extra_data.analysis.games.length){
        return [];
    }
    const game = extra_data.analysis.games[game_id];
    const moves = [];
    for(let i=0; i < game.moves.length; i++){
        moves.push(get_move(game_id, i));
    }
    return moves;
}

function get_cube_recommendation(cube_analysis, move_action){
    const drop_take = ["drop", "take"].includes(move_action);

    let best_action = null;
    const actions = [
        ["eq_dt", cube_analysis.eq_dt, 0],
        ["eq_dp", cube_analysis.eq_dp, 0],
    ];
    if(!drop_take){
        actions.push(["eq_nd", cube_analysis.eq_nd, 0])
    }
    if(cube_analysis.eq_nd > cube_analysis.eq_dp && !drop_take){
        best_action = actions[2];
    }else{
        actions.sort( (a, b) => a[1] - b[1] );
        best_action = actions[1]
    }
    for(let action of actions){
        action[2] = action[1] - best_action[1];
    }

    return {
        actions: actions,
        best: best_action[0],
    }
}

function parse_sgf_move(sgf_move, state){
    let position_names = "zxwvutsrqponmlkjihgfedcbay".split("");
    if(sgf_move == null){
        return {
            data: [],
            text: `Resigned`,
        }
    }
    if(["double", "drop", "take", "resigned"].includes(sgf_move)){
        return {
            data: sgf_move,
            text: sgf_move,
        }
    }
    if(["1", "2", "3", "4", "5", "6"].includes(sgf_move[0])){
        sgf_move = sgf_move.slice(2);
    }

    let dice = state.dice;
    dice.sort();
    dice.reverse();
    dice = dice.join("");

    if(sgf_move.length == 0){
        return {
            data: [],
            text: `(${dice}) no move`,
        }
    }


    const move_data = [];
    let from, to;
    for(let i=0; i < sgf_move.length; i+=2){
        from = position_names.findIndex((x) => x == sgf_move[i]),
        to = position_names.findIndex((x) => x == sgf_move[i+1])
        
        if(state.color == "B" && from == 25){
            from = 0;
        }
        move_data.push([from, to]);
    }
    return {
        data: move_data,
        sgf: sgf_move,
        text: `(${dice}) ${move_data_to_repr(move_data, state)}`,
    }
}

function merge_move_data(move_data){
    move_data.sort((a, b) => b[0] - a[0]);
    const simplified = [];
    let appended_move = false
    for( let [from_point, to_point] of move_data){
        appended_move = false;
        for(let move of simplified){
            if(move.at(-1) == from_point){
                move.push(to_point);
                appended_move = true;
                break;
            }
        }
        if(! appended_move){
            simplified.push([from_point, to_point]);
        }    
    }
    return simplified;
}

function move_data_to_repr(move_data, state){
    let blot_positions = (state.color == "W")? state.white_positions : state.black_positions;
    blot_positions = blot_positions.split("").map( (x) => parseInt(x, 26));
    
    if(state.color == "B"){
        move_data = move_data.map((x) => [(x[0] == 25) ? x[0] : 25 - x[0], 25- x[1]]);
        blot_positions = blot_positions.map( (x) => 25- x);
    }
    
    move_data = merge_move_data(move_data);
    const move_dict = {};
    let hit_move = [];
    for(let move of move_data){
        hit_move = [];
        for(let i in move){
            let m = move[i];
            let hit_marker = "";
            if(blot_positions.includes(m) && i > 0){
                // console.log(blot_positions, m);
                blot_positions[blot_positions.findIndex( (x) => x == m)] = "*";
                hit_marker = "*";
            }
            if((m == 0 || m == 25) && i == 0){
                m = "bar";
            }else if((m == 0 || m == 25) && i > 0){
                m = "off";
            }
            hit_move.push(`${m}${hit_marker}`);
        }
        const move_str = hit_move.join("/");
        move_dict[move_str] = (move_dict[move_str] || 0) + 1
    }

    let repr = [];
    for(let move_str in move_dict){
        if(move_dict[move_str] > 1){
            repr.push(`${move_str}(${move_dict[move_str]})`);
        }else{
            repr.push(move_str);
        }
    }
    return repr.join(" ");
}

function selectMove(move_id, alt_id=null){
    state.current = move_id;
    // move_id = Math.max(0, Math.min(move_id, game_analysis.moves.length-1));
    const move = get_move(extra_data.selected_game, move_id);
    if(alt_id == null){
        state.alt = move.move_played;
    }else{
        state.alt = alt_id;
    }
    extra_data.current_board = move.state.toPositionString();

    extra_data.arrows = [];
    if(move.move_analysis.length < alt_id || move.move_analysis.length == 0){
        return;
    }

    for(const arrow_ of move.move_analysis[state.alt].move.data){
        const arrow = arrow_.slice();
        extra_data.arrows.push({
            start: arrow[0],
            end: arrow[1],
        });
    }
}

function get_win_probabilities(){
    const move = get_move(extra_data.selected_game, state.current);
    if(move == null || Object.keys(move).length == 0){
        return {
            win: 0,
            win_g: 0,
            win_bg: 0,
            lose_g: 0,
            lose_bg: 0,
            eq: 0,
        };
    }
    let alt_id, win_probabilities;
    if(state.alt == null){
        alt_id = move.played;
    }else{
        alt_id = state.alt
    }
     
    if(move.move_analysis[alt_id] == null){
        win_probabilities = move.cube_analysis;
    }else{
        win_probabilities = move.move_analysis[alt_id];
    }
    return win_probabilities;
}

function handleImport(boardStates){
    state.boardStates = [];
    for(let i=0; i < boardStates.length - 1; i++){
        const currentPosition = new BoardState(boardStates[i]);
        const move = currentPosition.getMove(boardStates[i+1]);
        
        state.boardStates.push({positionString: boardStates[i], move: move});
        
    }
}

function getMatchLog(){
    const filename = match.match_id;
    const text = match.export_match().join("\n");
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function get_game_analysis(){
    if(extra_data.selected_game == null){
        return {moves: []};
    }
    if(extra_data.analysis == null || Object.keys(extra_data.analysis).length == 0){
        return {moves: []};
    }
    const game_analysis = extra_data.analysis.games[extra_data.selected_game];
    
    return game_analysis;
}

function get_cube_best_action(move_data){
    const recommendation = move_data.cube_recommendation;
    var best_action;
    if(Object.keys(recommendation).length > 0){
        best_action = recommendation.best;
    }else{
        best_action = "eq_nd";
    }
    return best_action;
}

function get_error(move_data){
    var move_error = 0;
    var cube_error = 0;
    if(move_data.move_played){
        const played_move_id = parseInt(move_data.move_played);
        if(played_move_id >= 0){
            const played_move = move_data.move_analysis[played_move_id];
            const top_move = move_data.move_analysis[0];
            move_error = Math.abs(parseFloat(top_move.eq) - parseFloat(played_move.eq));
        }
    }
    if(Object.keys(move_data.cube_analysis).length > 0){
        const best_action = get_cube_best_action(move_data);
        const eq_best = move_data.cube_analysis[best_action];

        const eq_nd = move_data.cube_analysis.eq_nd;
        const eq_dt = move_data.cube_analysis.eq_dt;
        const eq_dp = move_data.cube_analysis.eq_dp;

        if(move_data.move.data == "double"){
            cube_error = Math.min(eq_dt - eq_best, eq_dp - eq_best);
        }else if(move_data.move.data == "take"){
            if(eq_dt < eq_dp){
                cube_error = eq_dt - eq_dp;
            }else{
                cube_error = 0;
            }
        }else if(move_data.move.data == "drop"){
            if(eq_dt > eq_dp){
                cube_error = eq_dp - eq_dt;
            }else{
                cube_error = 0;
            }
        }else{
            cube_error = eq_nd - eq_best
        }
    }
    return [Math.abs(move_error), Math.abs(cube_error)];
}

function get_move_class(move_data, checker=true, cube=true){
    const [move_error, cube_error] = get_error(move_data);
    const classes = [];
    if(checker){
        if(move_error > 0.02 && move_error < 0.08){
            classes.push("error");    
        }else if(move_error > 0.08){
            classes.push("blunder");
        }else{
            classes.push("ok");
        }
    }
    
    if(cube){
        if(cube_error > 0.02 && cube_error < 0.08){
            classes.push("error");    
        }else if(cube_error > 0.08){
            classes.push("blunder");
        }else{
            classes.push("ok");
        }
    }

    return classes;
}

function get_move_data(move_id){
    const current_state = getCurrentPosition(move_id);
}

function get_score(){
    var score = {"W":0, "B":0}
    if(match){
        score = match.get_score(extra_data.selected_game); 
        score["points"] = match.points;
        score["crawford"] = match.is_crawford(extra_data.selected_game);
    }
    
    return score;
}

async function upgrade_position_analysis(current_position){
    extra_data.upgrade_button_disabled = true;
    const move = get_game_analysis().moves[state.current];
    const new_analysis = await getPositionAnalysis(
            extra_data.current_board, move.chequer[move.played])

    if(new_analysis.chequer.moves){
        move.chequer = new_analysis.chequer.moves;
        move.ply = "3C";
        move.played = new_analysis.chequer.played;
    }

    if(new_analysis.double.double){
        move.double = new_analysis.double.double;
        move.ply = "3C";
    }

    extra_data.upgrade_button_disabled = false;
    extra_data.message.text = "Upraded the move analysis to: "+move.ply
}

async function create_puzzles_for_match(match_id){
    extra_data.creating_puzzles = true;
    const response = await fetch(app_server + `/match/${ match_id }/createpuzzles/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
        }),
    });
    const puzzles_data = await response.json();
    extra_data.message.text = puzzles_data.message;
    extra_data.creating_puzzles = false;
}

function click_to_advance(event){
    const board_elem = event.srcElement.closest(".board");
    const rect = board_elem.getBoundingClientRect()
    const midpoint = (rect.left + rect.right)/2;
    const mouse_x = event.clientX;

    if(mouse_x < midpoint){
        state.current -= 1;
        selectMove(state.current);
    }else{
        state.current += 1;
        selectMove(state.current);
    }
}

</script>

<template>
<Message :message="extra_data.message" />
<Header />

<div class="h-rest flex flex-col md:flex-row">
    <div v-show="extra_data.show == 'moves'" 
        class="w-full md:w-1/3 md:px-2 flex flex-col md:h-rest flex-shrink-0 py-2"
    >
        <div class="flex gap-x-2 my-3 order-last md:order-first">
            <button @click="getMatchExport()"
                    class="btn btn-blue"
                    :disabled="extra_data.loading_export"
            >
                Download
            </button>
            <button @click="extra_data.show='statistics'"
                    class="btn btn-blue"
            >
                Show Statistics
            </button>
            <button :disabled="extra_data.analysis_data.config == '3ply' || extra_data.requested_3ply_analysis"
                    @click="getAnalysis(true, '3ply'); extra_data.requested_3ply_analysis = true"
                    class="btn btn-blue"
            >
                Upgrade to 3ply
            </button>
            <button 
                    @click="getAnalysis(true, extra_data.analysis.config);"
                    class="btn btn-blue hidden"
            >
                reload 
            </button>
        </div>
        
        <div class="flex justify-between mb-4">
            <h2 class="text-xl align-bottom">Analysis</h2>
            <select v-model="extra_data.selected_game" class="pr-10 py-1 px-0 block border-0 appearance-none peer focus:ring-0">
                <option v-for="(game, index) in match.games" :value="index" :key="index">
                    Game {{ index + 1 }}
                </option>
            </select>
        </div>
        <div v-if="extra_data.analysis_data.status != 'analysed'">
            Analyzing, please wait...
        </div>
        <div class="max-h-[50vh] md:max-h-none grid grid-cols-2 gap-x-2 gap-y-2
                    overflow-auto 
                    content-start pb-10"
            v-if="extra_data.selected_game != null && extra_data.analysis != null"
        >
            <div class="col-span-2 hidden md:block">
                <Line :data="get_chart_data()" :options="chart_options" />
            </div>
        
            <div class="text-lg font-semibold">
                <span class="rounded-full bg-stone-w-color border-case-color border w-4 h-4 inline-block" />
                {{ extra_data.match.white.username}}</div>
            <div class="text-lg font-semibold">
                <span class="rounded-full bg-stone-b-color  border-case-color border w-4 h-4 inline-block" />
                {{ extra_data.match.black.username}}</div>
            <div class="move w-full text-center" v-if="getCurrentPosition() == undefined">No moves in this game</div>
            <div class="move" v-if="get_move(extra_data.selected_game, 0).state.color == 'W'" />
            <div class="move w-full flex-row flex-nowrap items-center"
                :class="{highlight: state.current == index}"
                v-for="(move_data, index) in get_moves(extra_data.selected_game)"
                @click="selectMove(index)"
            >
                <span class="hidden md:inline-block w-8 text-case-color"
                    v-if="get_move(extra_data.selected_game, 0).state.color == 'W' ? index % 2 == 1: index % 2 == 0"
                >
                    {{ index + 1 }})
                </span>
                <span v-if="move_data.move" class="tabular-nums">
                    {{ move_data.move.text }}
                </span>
                <span v-else>
                    {{ move_data.move_sgf }}
                </span>
                <span class="float-right pr-4">
                    <div :class="get_move_class(move_data, true, false)" 
                          class="w-5 h-5 ml-1 inline-flex justify-center items-center rounded-full"
                    />
                    <div :class="get_move_class(move_data, false, true)" 
                          class="w-5 h-5 ml-1 inline-flex justify-center items-center rounded"
                    />
                </span>
                
            </div>
        </div>
        <div
            :set="move = get_move(extra_data.selected_game, state.current)"
        >
        <div
            class="border-solid border bg-case-light-color" 
            :set="win_probabilities = get_win_probabilities()"
        >
            <table class="w-full" v-if="Object.keys(win_probabilities).length > 0">
                <tr>
                <th>Win</th>
                <th>Win G</th>
                <th>Win BG</th>
                <th>Lose G</th>
                <th>Lose BG</th>
                <th>Equity</th>
                </tr>
                
                <tr>
                <td class="text-center">{{ win_probabilities.win.toFixed(3) }}</td>
                <td class="text-center">{{ win_probabilities.win_g.toFixed(3) }}</td>
                <td class="text-center">{{ win_probabilities.win_bg.toFixed(3) }}</td>
                <td class="text-center">{{ win_probabilities.lose_g.toFixed(3) }}</td>
                <td class="text-center">{{ win_probabilities.lose_bg.toFixed(3) }}</td>
                <td class="text-center">{{ win_probabilities.eq.toFixed(3) }}</td>
                </tr>
            </table>
        </div>
        <div
            class="border-solid border bg-case-light-color" 
        >
            <h2 class="text-lg font-semibold flex justify-between pr-2 pt-2 pb-2">
                <span>Move Analysis</span> 
                <span class="flex gap-x-2 align-items-center">
                    <!--
                    <span class="w-6 h-6 inline-block text-field-color hover:text-field-light-color cursor-pointer"
                          @click="puzzle_data.state = extra_data.current_board"
                    >
                        <PuzzlePieceIcon/>
                    </span>
                    -->
                    <router-link
                            v-if="extra_data.current_board" 
                            :to="{name: 'position', params:{position_id: extra_data.current_board}}">
                            <CpuChipIcon class="w-6 h-6 inline-block text-field-color hover:text-field-light-color cursor-pointer" />
                    </router-link>
                    <!--
                    <button class="btn btn-blue" 
                            @click="copy_to_clipboard( get_xgid() )">
                        XGID
                    </button>
                    <button class="btn btn-blue" :disabled="extra_data.upgrade_button_disabled || move.ply == '3C'"
                            @click="upgrade_position_analysis(state.current)">3-ply</button>
                    -->
                </span>
            </h2>
            <div
                v-for="alternative_play, m_id in move.move_analysis"
                class="flex justify-between tabular-nums move"
                :class="{highlight: m_id == state.alt}"
                @click="selectMove(state.current, m_id)"
            >
                <span :class="{'font-bold': m_id == move.move_played}">
                    {{ alternative_play.move.text }}
                </span>
                <span>
                    {{ alternative_play.eq.toFixed(3) }}
                    (-{{ (move.move_analysis[0].eq - alternative_play.eq).toFixed(3) }})
                </span>
            </div>
        </div>
        <div
            class="border-solid border bg-case-light-color" 
            :set="move = get_move(extra_data.selected_game, state.current)"
            v-if="Object.keys(move).length > 0 && Object.keys(move.cube_analysis).length > 0"
        >
            <h2 class="text-lg font-semibold">
                Cube Analysis 
            </h2>
            <div class="flex justify-between">
            </div>
            <div class="flex justify-between" 
                 v-if="move.move_sgf != 'drop' && move.move_sgf != 'take'">
                <span>Cubeless Equity</span>
                <span class="tabular-nums">
                    {{ move.cube_analysis.eq.toFixed(3) }}
                </span> 
            </div>
            <div class="flex justify-between" 
                 v-for="rec in move.cube_recommendation.actions"
            >
                <span :class="{'font-semibold' : move.cube_recommendation.best == rec[0]}">
                    {{ cube_action_names[rec[0]] }}
                </span>
                <span class="tabular-nums"
                      :class="{'font-semibold' : move.cube_recommendation.best == rec[0]}"
                >
                    {{ (rec[1]).toFixed(3).padStart(6, ' ') }} ({{ ((rec[2]).toFixed(3)).padStart(6, ' ') }})</span>
            </div>
        </div>
        </div>
    </div>
    <div v-show="extra_data.show == 'statistics'" 
         class="overview flex flex-col h-full flex-shrink-0 py-2 px-2">
        <div class="flex gap-x-2 mb-3">
            <button @click="getMatchExport()"
                    class="btn btn-blue"
                    :disabled="extra_data.loading_export"
            >
                Download
            </button>
            <router-link :to="{name:'export-animation', params:{match_id: match.match_id}}">
                <button class="btn btn-blue">
                    Create GIF
                </button>
            </router-link>
            <button @click="extra_data.show='moves'"
                    class="btn btn-blue"
            >
                Show Moves
            </button>
        </div>
        <div class="mb-4" v-if="extra_data.analysis_data.status == 'analysed'">
            <div class="font-thin">
                <p>Config: {{ extra_data.analysis.config}}</p>
                <p>Time: {{ extra_data.analysis.analysis_time.toFixed(2)}} seconds</p>
            </div>
        </div>
        <div class="flex justify-between mb-4">
            <h2 class="text-xl align-bottom">Match Statistics</h2>
            <select v-model="extra_data.selected_game" class="">
                <option v-for="(game, index) in match.games" :value="index" :key="index">
                    Game {{ index + 1 }}
                </option>
            </select>
        </div>
        <div>
            <h1 class="text-lg font-semibold">Match</h1>
            <table class="w-full" v-if="extra_data.analysis != null"
            >
                <div 
                   :set_w="W = extra_data.analysis.match_stats.W"
                   :set_b="B = extra_data.analysis.match_stats.B" />
                <tr>
                    <td class="w-1/3" />
                    <td class="w-1/3 text-lg font-semibold text-right">
                        <span class="rounded-full bg-stone-w-color border-case-color border w-4 h-4 inline-block" />
                        {{ extra_data.match.white.username}}</td>
                    <td class="w-1/3 text-lg font-semibold text-right">
                        <span class="rounded-full bg-stone-b-color  border-case-color border w-4 h-4 inline-block" />
                        {{ extra_data.match.black.username}}</td>
                </tr>
                <tr>
                    <th class="text-left">Equity Lost</th>
                    <td class="text-right">{{ W.EQ.toFixed(3) }}</td>
                    <td class="text-right">{{ B.EQ.toFixed(3) }}</td>
                </tr>
                <tr>
                    <th class="text-left">Error Rate</th>
                    <td class="text-right">{{ (W.ER || W.PR || 0).toFixed(3) }}</td><!-- BACKWARDS COMPATIBILITY CAN BE REMOVED IN 3 MONTHS (SEPT-2024) -->
                    <td class="text-right">{{ (B.ER || B.PR || 0).toFixed(3) }}</td><!-- BACKWARDS COMPATIBILITY CAN BE REMOVED IN 3 MONTHS (SEPT-2024) -->
                </tr>
                <tr v-if="W.XR != null"><!-- BACKWARDS COMPATIBILITY CAN BE REMOVED IN 3 MONTHS (SEPT-2024) -->
                    <th class="text-left">GNU PR</th>
                    <td class="text-right">{{ W.XR.toFixed(3) }}</td>
                    <td class="text-right">{{ B.XR.toFixed(3) }}</td>
                </tr>
                <tr>
                    <th class="text-left">Level of Play</th>
                    <td class="text-right">{{ W.level[2] }}</td>
                    <td class="text-right">{{ B.level[2] }}</td>
                </tr>
                <tr>
                    <th class="text-left">Move Equity</th>
                    <td class="text-right">{{ W["move EQ"].toFixed(3) }}</td>
                    <td class="text-right">{{ B["move EQ"].toFixed(3) }}</td>
                </tr>
                <tr>
                    <th class="text-left">Move PR</th>
                    <td class="text-right">{{ (W["move PR"]*1000).toFixed(3) }}</td>
                    <td class="text-right">{{ (B["move PR"]*1000).toFixed(3) }}</td>
                </tr>
                <tr>
                    <th class="text-left">Cube Equity</th>
                    <td class="text-right">{{ W["cube EQ"].toFixed(3) }}</td>
                    <td class="text-right">{{ B["cube EQ"].toFixed(3) }}</td>
                </tr>
                <tr>
                    <th class="text-left">Cube PR</th>
                    <td class="text-right">{{ (W["cube PR"]*1000).toFixed(3) }}</td>
                    <td class="text-right">{{ (B["cube PR"]*1000).toFixed(3) }}</td>
                </tr>
            </table>
        </div>
        <div class="mt-8">
            <h1 class="text-lg font-semibold">Game {{ extra_data.selected_game + 1 }}</h1>
            <table class="w-full" v-if="extra_data.analysis != null"
            >
                <div 
                   :set_move="move_stats = get_game_analysis().statistics.move_stats"
                   :set_cube="cube_stats = get_game_analysis().statistics.cube_stats"
                   :set_dice="dice_stats = get_game_analysis().statistics.dice_stats"
                />
                <tr>
                    <td class="w-1/3" />
                    <td class="w-1/3 text-lg font-semibold text-right">
                        <span class="rounded-full bg-stone-w-color border-case-color border w-4 h-4 inline-block" />
                        {{ extra_data.match.white.username}}</td>
                    <td class="w-1/3 text-lg font-semibold text-right">
                        <span class="rounded-full bg-stone-b-color  border-case-color border w-4 h-4 inline-block" />
                        {{ extra_data.match.black.username}}</td>
                </tr>
                <tr>
                    <th class="text-left">Move Equity</th>
                    <td class="text-right">{{ move_stats["EQ W"].toFixed(3) }}</td>
                    <td class="text-right">{{ move_stats["EQ B"].toFixed(3) }}</td>
                </tr>
                <tr>
                    <th class="text-left">Blunders/Errors</th>
                    <td class="text-right">{{ move_stats["Vbad W"] }}/{{ move_stats["blunder W"]}}/{{ move_stats["error W"] }}</td>
                    <td class="text-right">{{ move_stats["Vbad B"] }}/{{ move_stats["blunder B"]}}/{{ move_stats["error B"] }}</td>
                </tr>
                <tr>
                    <th class="text-left">Moves/Unforced</th>
                    <td class="text-right">{{ move_stats["moves W"] }}/{{ move_stats["unforced W"]}}</td>
                    <td class="text-right">{{ move_stats["moves B"] }}/{{ move_stats["unforced B"]}}</td>
                </tr>
                <tr>
                    <th class="text-left">Luck</th>
                    <td class="text-right">{{ parseFloat(dice_stats["luck W"]).toFixed(3) }}</td>
                    <td class="text-right">{{ parseFloat(dice_stats["luck B"]).toFixed(3) }}</td>
                </tr>
                <tr>
                    <th class="text-left">VL/L/U/VU</th>
                    <td class="text-right">{{ dice_stats["very lucky W"] }}/{{ dice_stats["lucky W"] }}/{{ dice_stats["unlucky W"] }}/{{ dice_stats["very unlucky W"] }}</td>
                    <td class="text-right">{{ dice_stats["very lucky B"] }}/{{ dice_stats["lucky B"] }}/{{ dice_stats["unlucky B"] }}/{{ dice_stats["very unlucky B"] }}</td>
                </tr>
            </table>
        </div>
        <div class="mt-8 hidden">
            <h1 class="text-2xl font-semibold mb-2">Debug</h1>
            <select v-model="extra_data.analysis_config" class="mr-2">
                <option :value="conf" v-for="conf in analysis_configs">{{ conf }}</option>
            </select>
            <button class="btn btn-blue hidden" :disabled="extra_data.reloading_analysis"
                @click="getAnalysis(true, extra_data.analysis_config)">
                Reload Analysis (debug)
            </button>
            <button class="btn btn-blue"
                @click="create_puzzles_for_match(match.match_id)"
                :disabled="extra_data.creating_puzzles"
            >
                Create Puzzles (debug)
            </button>
        </div>
    </div>

    <div class="board w-full grow order-first md:order-last"
         @click.prevent="click_to_advance"
    >
    <Board 
        :positionString="extra_data.current_board" 
        :score="get_score()"
        :player_color="extra_data.user_color"
        :arrows="extra_data.arrows"
        :show_pip_numbers="true"
         :can_do_actions="false"
        @move-end=""
    />
    </div>
</div>

<CreatePuzzle 
    :state="puzzle_data.state"
    :match_id="match.match_id"
    @cancel="puzzle_data.state = ''"
    @puzzle-created="puzzle_data.state = ''"
/>
</template>

<style scoped>
.move{
    cursor:pointer;
}

.overview{
    min-width:32em;
    overflow-y:auto;
}

.error{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--error-color));
    color: #fff;
    color: color-mix(in srgb,white 80%,var(--error-color));
    background-color: var(--error-color);
}
.error::after {
    content: "?";
}

.blunder{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--blunder-color));
    color: #fff;
    color: color-mix(in srgb,white 80%,var(--blunder-color));
    background-color: var(--blunder-color);
    letter-spacing: -.1em;
}
.blunder::after {
    transform: translate(-.03em);
    content: "??";
}

.brilliant{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--brilliant-color));
    color: #fff;
    color: color-mix(in srgb,white 80%,var(--brilliant-color));
    background-color: var(--brilliant-color);
}
.brilliant::after {
    content: "!!";
}

.critical{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--critical-color));
    color: #fff;
    color: color-mix(in srgb,white 80%,var(--critical-color));
    background-color: var(--critical-color);
}
.critical::after {
    content: "!!";
}

.dubious{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--error-color));
    color: #fff;
    color: color-mix(in srgb,white 80%, var(--error-color));
    background-color: color-mix(in srgb, white 20%, var(--error-color));
}
.dubious::after {
    content: "?!";
}

.ok{
    display:none;
}

.highlight{
    background-color:var(--field-light-color);
}
</style>
