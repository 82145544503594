<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CalendarIcon, CalculatorIcon, PuzzlePieceIcon, BoltIcon, AcademicCapIcon, ChatBubbleLeftRightIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import Header from './Header.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const tag_options = ["holding", "anchor", "blitz", "prime", "end game", "opening", "last roll", "no contact"];

const options = [
    {
        "name": "Puzzle",
        "description": "Solve puzzles to test your skills and scale the leaderboard",
        "icon": PuzzlePieceIcon,
        "router_to": () => router.push({name:'puzzle', params:{'puzzle_id': 'random'}}),
    },
    {
        "name": "Rush",
        "description": "Solve as many puzzles as you can in 3 minutes. Beware, if you lose more than 200 millipoints of equity you are out.",
        "icon": BoltIcon,
        "router_to": () => router.push({name: 'quiz', params:{quiz_id: 'rush'}}),
    },
    {
        "name": "Quiz",
        "description": "Want to practice your weak spots? This is the place. This quiz contains puzzles of your level in the categories you make most mistakes.",
        "icon": AcademicCapIcon,
        "options": [
            ["Number of Puzzles", [
                {
                    "title": "5",
                    "value": 5,
                    "key": "nrof_puzzles",
                    "action": (x) => custom_quiz_data.nrof_puzzles = x.value,
                    "is_active": (x) => custom_quiz_data.nrof_puzzles == x.value,
                },
                {
                    "title": "10",
                    "value": 10,
                    "key": "nrof_puzzles",
                    "action": (x) => custom_quiz_data.nrof_puzzles = x.value,
                    "is_active": (x) => custom_quiz_data.nrof_puzzles == x.value,
                },
                {
                    "title": "20",
                    "value": 20,
                    "key": "nrof_puzzles",
                    "action": (x) => custom_quiz_data.nrof_puzzles = x.value,
                    "is_active": (x) => custom_quiz_data.nrof_puzzles == x.value,
                },
            ]
            ],["Topics", [
                ...tag_options.map( (tag) => {
                    return {
                        "title": tag,
                        "key": "tags",
                        "action": (x) => custom_quiz_data.tags[x.title] = true ^ custom_quiz_data.tags[x.title],
                        "is_active": (x) => custom_quiz_data.tags[x.title],
                    }
                }), {
                    "title": 'random',
                    "key": "tags",
                    "action": (x) => custom_quiz_data.tags = {},
                    "is_active": (x) => Object.keys(custom_quiz_data.tags).length == 0,
                }
            ]
            ]
        ],
        "router_to": create_custom_quiz,
    },
    {
        "name": "Explanations",
        "description": "Here you can browse the most recent explanations added to puzzles.",
        "icon": ChatBubbleLeftRightIcon,
        "router_to": () => router.push({name: 'puzzle-comments'}),
    },
    {
        "name": "Daily",
        "description": "Solve a new puzzle each day.",
        "icon": CalendarIcon,
        "router_to": () => router.push({name:'daily-puzzle'}),
    },
    {
        "name": "Pip-Rush (Coming Soon)",
        "description": "Are you preparing for live play and you forgot that there is no pip counter on a real tournament board? Then, this is the place to be to practice you pip counting.",
        "icon": CalculatorIcon,
        "router_to": () => router.push({name: 'quiz', params:{quiz_id: 'pip'}}),
    },
];

const custom_quiz_data = reactive({
    nrof_puzzles: 5,
    tags: {},
});

const extra_data = reactive({
    selected_choice: options[0],
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const route = useRoute();
const tournament_id = route.params.tournament_id;

async function create_quiz_for_user(){
    const response = await fetch(app_server + "/quiz/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            quiz_type: "user",
            quiz_user_id: userStore.info.user_id, 
        }),
    });
    const data = await response.json();
    console.log("Quiz:", data);
    if(data.status == "success"){
        console.log("SUCCESS!!!!");
        router.replace({name:"quiz", params:{quiz_id: data.quiz_id}});
    }
}
    
async function create_custom_quiz(){
    const quiz_data = {
        "nrof_puzzles": custom_quiz_data.nrof_puzzles,
        "tags": Object.keys(custom_quiz_data.tags),
    };
    const response = await fetch(app_server + "/quiz/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            quiz_type: "custom",
            quiz_user_id: userStore.info.user_id, 
            ...quiz_data,
        }),
    });
    const data = await response.json();
    console.log("Quiz:", data);
    if(data.status == "success"){
        router.replace({name:"quiz", params:{quiz_id: data.quiz_id}});
    }
}
    

onMounted(() => {
});


</script>

<template>
<div class="flex flex-col">
    <Header />
     
    <div class="container mx-auto max-w-xl flex flex-col items-center gap-8">
        <div class="mx-auto w-96 bg-case-med-color text-stone-w-color flex flex-col items-center gap-y-4 p-4 rounded-lg relative"
        >
            <h1 class="text-4xl font-bold"></h1>
            <div class="flex flex-col items-center gap-y-4">
                <component :is="extra_data.selected_choice.icon" 
                    class="w-36 h-36 overflow-hidden">
                </component>

                <p class="text-xl font-semibold">
                    {{ extra_data.selected_choice.name}}</p>
            </div>
            <p class="text-center min-h-[5em]">
                {{ extra_data.selected_choice.description }}
            </p>
            <div class="w-full flex flex-col gap-y-3"
               v-if="extra_data.selected_choice.options"
                 v-for="optiongroup in extra_data.selected_choice.options"
            >
                <div class="text-lg">
                    {{ optiongroup[0] }}
                </div>
                <div class="grid grid-cols-3 gap-x-2 gap-y-2 w-full"
                     v-if="optiongroup[1]"
                >
                    <div v-for="option in optiongroup[1]"
                        class="h-12 rounded transition-all text-lg font-semibold
                            flex items-center justify-center cursor-pointer capitalize"
                        :class="{
                            'bg-field-color text-stone-w-color ring': option.is_active(option),
                            ' bg-stone-w-color text-case-med-color': !option.is_active(option),
                        }"
                        @click="option.action(option)"
                    >
                        {{ option.title }}
                    </div>
                </div>
            </div>
            <button class="btn btn-blue h-12 w-1/3 text-2xl"
                @click="extra_data.selected_choice.router_to()"
                :disabled="extra_data.selected_choice.disabled"
            >
                Play
            </button>
        </div>
        <div class="grid grid-cols-3 gap-8"
        >
            <div class="w-24 h-24 bg-case-light-color shadow-lg p-2 rounded-lg ring-offset-2 ring-field-color overflow-hidden relative cursor-pointer"
                 :class="{ring: option.name == extra_data.selected_choice.name}"
                v-for="option in options"
                @click="extra_data.selected_choice = option"
            >
                <div class="flex flex-col items-center gap-y-4 text-field-color">
                    <component :is="option.icon" 
                        class="w-16 h-16">
                    </component>
                </div>
                <p class="font-thin text-center">
                    {{ option.name}}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<style>
</style>

