import { defineStore } from 'pinia'
import {useMessageStore } from '@/stores/messagestore.js'
import {useSSEStore } from '@/stores/ssestore.js'
import {useUserStore } from '@/stores/userstore.js'

const app_server = import.meta.env.VITE_APP_SERVER;

export const useChatStore = defineStore('chat', {
    state: () => { return {
        chats: {},
        active_chats: [],
        user: {},
    };},
    getters: {
        unread: (state) => Math.min(99, Object.values(state.chats).reduce((ps, x) => ps + (x.unread || 0), 0)), 
        sorted_chats: (state) => {
            if(Object.keys(state.chats).length == 0){
                return []
            }

            return Object.values(state.chats).sort( (a, b) => {
                if(a == null || a.messages == null || a.messages.length == 0){
                    return -1;
                }
                if(b == null || b.messages == null || b.messages.length == 0){
                    return 1;
                }
                return -(a.messages.at(-1).timestamp - b.messages.at(-1).timestamp)
            });
        },
    },
    actions:{
        async start_chat(username){
            const response = await fetch(app_server + `/chat/${username}/start/`, {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            if(data.status == "success"){
                await this.get_chat(data.lobby);
                return this.chats[data.lobby];
            }else{
                console.error("Error starting chat", data);
            }
        },
        async get_chat(chat_key){
            const response = await fetch(app_server + `/chat/${chat_key}/`, {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            this.chats[data.lobby] = {
                lobby : data.lobby,
                other: data.other,
                info: data.info,
                messages: data.messages,
            };
            this.update_unread(chat_key);
            // TODO sort messages by timestamp
        },
        async send_chat(chat_key, message){
            if(message == null || message.length == 0){
                return;
            }
            const response = await fetch(app_server + `/chat/${chat_key}/`, {
                method: "POST",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify({
                    message: message,
                }),
            });
            const data = await response.json();

            if(data.status == "error"){
                // TODO error
                console.error("sending chat", data);
            }else{
                this.chats[data.lobby].messages.push(data.message_sent);
                const lastread_key = `lastread:${this.user.user_id}`;
                this.chats[chat_key].info[lastread_key] = Date.now() / 1000;
                this.update_unread(chat_key);
            }
        },
        async get_active_chats(){
            const response = await fetch(app_server + `/chat/`, {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            if(data.status == "success"){
                this.chats = {}; // Hopefully this will fix the problem with chats not appearing
                this.active_chats = data.active_chats;
                this.load_active_chats();
            }
        },
        async load_active_chats(limit=5){
            for(var i=0; i < limit; i++){
                if(this.active_chats.length == 0){
                    break;
                }
                const next_chat_key = this.active_chats.shift();
                await this.get_chat(next_chat_key);
            }
        },
        async connect(){
            this.connect_sse();
            this.get_active_chats();

            const userStore = useUserStore();
            await userStore.loadUser();
            this.user = userStore.info;
        },
        connect_sse(){
            const sseStore = useSSEStore();
            sseStore.connect();
            sseStore.addListener("chat", (data) => {
                if(this.chats[data.lobby] == null){
                    this.get_chat(data.lobby);
                }else{
                    this.chats[data.lobby].messages.push(data.message);
                    this.update_unread(data.lobby);
                    this.last_message = data.message;
                }
            });
        },
        disconnect(){

        },
        update_unread(chat_key){
            const lastread_key = `lastread:${this.user.user_id}`;
            const chat = this.chats[chat_key];
            if(chat == null || chat.info == null){
                return;
            }
            const last_read = parseFloat(chat.info[lastread_key] || 0);
            const unread_messages = chat.messages.filter(x => x.timestamp > last_read && x.sender != this.user.user_id);
            chat.unread = unread_messages.length;
            this.update_title();
        },
        set_read(chat_key){
            const chat = this.chats[chat_key];
            if(chat.unread == 0){
                return;
            }
            const lastread_key = `lastread:${this.user.user_id}`;
            if(chat == null){
                return;
            }
            chat.info[lastread_key] = Date.now()/1000;
            this.update_unread(chat_key);
        },
        update_title(){
            const title_parts = document.title.split(" - ", 2);
            if(this.unread == 0){
                document.title = title_parts.at(-1);
                return;
            }
            if(title_parts.length > 1){
                document.title = `(${this.unread}) - ${title_parts[1]}`;
            }else{
                document.title = `(${this.unread}) - ${title_parts[0]}`;
            }
        },
    }
});

